import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { salesReturn } = Dictionary

const salesReturnService = {

    salesReturnList: async (paramsObj:{}) => {
        return axios.get(BASE_URL + salesReturn.salesReturnList(), { params: paramsObj })
    },
    salesReturnSubmit: async (id:string,payload:{}) => {
        return axios.post(BASE_URL + salesReturn.salesReturnSubmit(id), payload)
    },

    salesReturnHistoryList: async (paramsObj:{}) => {
        return axios.get(BASE_URL + salesReturn?.salesReturnHistoryList(), { params: paramsObj })
    },
    downloadCreditNotesPdf:async (id:string)=>{
        return axios.get(BASE_URL + salesReturn?.downloadCreditNotesPdf(id))
    },
    shareCreditNotesPdf:async (id:string, paramsObj:{})=>{
        return axios.get(BASE_URL + salesReturn?.downloadCreditNotesPdf(id), {params:paramsObj})
    },

}
export default salesReturnService
