import React, { useState } from "react";
import { FiSearch } from "react-icons/fi";
import "./AddInfoSearch.scss";
import constant from "../../../constants/constant";

interface SearchComponentProps {
    className?: string;
    placeholder?: string;
    searchKeyword?: string;
    inputRef?: React.RefObject<HTMLInputElement>;
    searchCallBack: (keyword: string) => void;
    handleSearchType: (value: string) => void;
    barcodePlaceholder?: string;
    searchType: string;
}

const SearchComponent: React.FC<SearchComponentProps> = ({
    className,
    placeholder,
    searchKeyword,
    inputRef,
    searchCallBack,
    handleSearchType,
    barcodePlaceholder,
    searchType,
}) => {
    const [search, setSearch] = useState<string>();
    let debounce: any;
    const handleSearch = (e: string) => {
        setSearch(e);
        clearTimeout(debounce);
        debounce = setTimeout(() => {
            searchCallBack(e);
        }, 1000);
    };

    return (
        <div className={`search-container ${className}`}>
            <select
                className="select-dropdown text-muted"
                onChange={(e: any) => {
                    handleSearchType(e.target.value);
                    setSearch("");
                }}
                defaultValue={searchType}
            >
                {constant.Product_List_Search_Option.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>

            {/* Input field */}
            <input
                className="search-input"
                autoComplete="off"
                type="text"
                name="keyword"
                id="search"
                value={search}
                placeholder={
                    (searchType === constant.Product_List_Search_Option[0].value
                        ? placeholder
                        : barcodePlaceholder) || "Search"
                }
                defaultValue={searchKeyword}
                ref={inputRef}
                onChange={(e) => handleSearch(e.target.value)}
            />

            {/* Search icon */}
            <span className="search-icon-product-list">
                <FiSearch />
            </span>
        </div>
    );
};

export default SearchComponent;
